import React, { useEffect, useRef, useState } from 'react';
import { TextField, Button, Grid } from '@mui/material';
import ForceGraph2D, { ForceGraphMethods, NodeObject } from 'react-force-graph-2d';

interface Node {
    id: string;
    name: string;
    color: string;
    x?: number;
    y?: number;
}

interface Link {
    source: string;
    target: string;
}

interface GraphData {
    nodes: Node[];
    links: Link[];
}

const GraphComponent = () => {
    const [graphData, setGraphData] = useState<GraphData>({ nodes: [], links: [] });
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [authenticated, setAuthenticated] = useState(false);
    const fgRef = useRef<ForceGraphMethods<Node, Link>>();

    const fetchGraphData = async () => {
        try {
            const response = await fetch('https://api.be-native.life/api/friends/graphdata', {
                headers: {
                    'Authorization': 'Basic ' + btoa(`${username}:${password}`)
                }
            });
            const data: GraphData = await response.json();

            // Remove duplicate links
            const uniqueLinks = data.links.reduce<Record<string, Link>>((acc, link) => {
                const key = [link.source, link.target].sort().join('-');
                if (!acc[key]) {
                    acc[key] = link;
                }
                return acc;
            }, {});

            setGraphData({ nodes: data.nodes, links: Object.values(uniqueLinks) });
            setAuthenticated(true);
        } catch (error) {
            console.error('Error fetching graph data:', error);
            setAuthenticated(false);
        }
    };

    useEffect(() => {
        if (authenticated) {
            const fg = fgRef.current;
            if (fg) {
                fg.d3Force('link')?.distance(200); // Increase the distance between nodes
                fg.d3Force('charge')?.strength(-300); // Increase repulsion between nodes
            }
        }
    }, [authenticated]);

    const drawNode = (node: NodeObject<Node>, ctx: CanvasRenderingContext2D, globalScale: number) => {
        // Draw the circle
        ctx.beginPath();
        ctx.arc(node.x!, node.y!, 5, 0, 2 * Math.PI, false);
        ctx.fillStyle = node.color;
        ctx.fill();

        // Draw the label
        const label = node.name;
        const fontSize = 12 / globalScale;
        ctx.font = `${fontSize}px Sans-Serif`;
        const textWidth = ctx.measureText(label).width;
        const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2);

        ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
        ctx.fillRect(node.x! - bckgDimensions[0] / 2, node.y! - bckgDimensions[1] / 2 + 7, bckgDimensions[0], bckgDimensions[1]);

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = 'black';
        ctx.fillText(label, node.x!, node.y! + 7);
    };

    return (
        <div>
            {!authenticated && (
                <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" sx={{ marginTop: '20px' }}>
                    <Grid item>
                        <TextField
                            label="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={fetchGraphData}>
                            グラフを描画
                        </Button>
                    </Grid>
                </Grid>
            )}
            {authenticated && (
                <ForceGraph2D
                    ref={fgRef}
                    graphData={graphData}
                    nodeCanvasObject={drawNode}
                    nodeAutoColorBy="id"
                    linkDirectionalArrowLength={6}
                    linkDirectionalArrowRelPos={1}
                    linkCurvature={0.25}
                />
            )}
        </div>
    );
};

export default GraphComponent;
